.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size:"14px";
}

.left-part {
    width: 30%;
    /* Adjust the width as per your requirement */
    display: flex;
    align-items: center;
}

.middle-part {
    width: 29%;
    /* Adjust the width as per your requirement */
    text-align: left;
    padding-left: 20px;
    margin-left: 291px;
    /* Adjust the space from the left as per your requirement */
}

.right-part {
    width: 30%;
    /* Adjust the width as per your requirement */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* Align items vertically */
}

/* Custom styling for radio button */
.right-part input[type="radio"] {
    display: none;
    /* Hide the default radio button */
}

/* Custom radio button design */
.right-part input[type="radio"]+label {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    /* Adjust the spacing */
    cursor: pointer;
    font-size: 16px;
}

.right-part input[type="radio"]+label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    border: 2px solid #4caf50;
    /* Green border color */
    border-radius: 50%;
}

/* Style for checked state */
.right-part input[type="radio"]:checked+label::after {
    content: "";
    position: absolute;
    left: 4px;
    top: 54%;
    /* Adjust the position */
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-color: #4caf50;
    /* Green color */
    border-radius: 50%;
}