.responsive {
  margin-left: 80px;
  /* margin-left: 230px; */
  margin-right: 20px;
  margin-top: 88px;
  /* margin-top: -50px; */
}

.report_responsive {
  margin-left: 80px;
  /* margin-left: 230px; */
  margin-right: 30px;
  margin-top: 10px;
}

.responsive_client {
  margin-right: 20px;
  transition: all 0.5s ease;

  margin-top: 88px;
}

.responsive_client_application {
  margin: 10px;
  transition: all 0.5s ease;
}

.maindashboard_responsivetop {
  transition: all 0.5s ease;
  margin-right: 60px;
  margin-top: 100px;
}

@media only screen and (max-width: 600px) {
  .responsive {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 1000px) {
  .maindashboard_responsive {
    flex-direction: column !important;
  }
}

.leadpadding {
  padding-right: 9px !important;
}

.clock-holder {
  width: 100%;
  background: #fff;
  margin: 20px auto;
  position: relative;
}

.stopwatch {
  padding: 60px 0px;
  text-align: center;
}

.stopwatch span {
  background: #00aba9;
  color: #fff;
  display: inline-block;
  font-size: 22px;
  padding: 20px;
  border-radius: 5px;
  width: 70px;
}

.stopwatch .stopwatch-btn {
  background: #fff;
  margin-top: 30px;
  margin-left: 5px;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  text-transform: uppercase;
  width: 150px;
  transition: background 1s;
  cursor: pointer;
}

.stopwatch .stopwatch-btn-red {
  border: 2px solid #c85454;
  color: #c85454;
}

.stopwatch .stopwatch-btn-yel {
  border: 2px solid #ffc900;
  color: #ffc900;
}

.stopwatch .stopwatch-btn-gre {
  border: 2px solid #00aba9;
  color: #00aba9;
}

.stopwatch .stopwatch-btn-red:hover {
  background: #c85454;
  border: 2px solid #c85454;
  color: #fff;
}

.stopwatch .stopwatch-btn-yel:hover {
  background: #ffc900;
  border: 2px solid #ffc900;
  color: #fff;
}

.stopwatch .stopwatch-btn-gre:hover {
  background: #00aba9;
  border: 2px solid #00aba9;
  color: #fff;
}

.todo-list li {
  margin-left: 50px !important;
}

.ck-content li {
  margin-left: 30px;
}

.client_container2 {
  border-bottom: 5px solid #007bff;
  position: relative;
  width: 300px;
  line-height: 28px;
  overflow: hidden;
  margin-top: 35px;
  margin-bottom: 27px;
}

.client_container2 span {
  color: #fff;
  background: #007bff;
  font-weight: bolder;
  display: inline-block;
  /* padding: 5px 40px; */
  padding: 3px 30px 0 13px;
}

.client_container2 span::after {
  position: absolute;
  content: "";
  width: 19%;
  height: 84%;
  background: #007bff;
  transform: rotate(59deg);
}

.client_container {
  border-bottom: 5px solid #007bff;
  /* border-bottom: 5px solid #000; */
  position: relative;
  width: 300px;
  line-height: 28px;
  overflow: hidden;
  margin-top: 38px;
  margin-bottom: 27px;
  /* margin-left: -13px; */
}

.client_container span {
  color: #fff;
  background: #000;
  font-weight: bolder;
  background: #007bff;
  display: inline-block;
  /* padding: 5px 40px; */
  padding: 3px 30px 0 13px;
}

.client_container span::after {
  position: absolute;
  content: "";
  width: 19%;
  height: 84%;
  background: #007bff;
  transform: rotate(59deg);
}

.errormessage {
  background-color: white;
  color: red;
  text-align: left;
  padding: 2px;
  margin: 0 !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.paddingright0 {
  padding-right: 0 !important;
}

.form-group {
  border: none !important;
  padding: 0 !important;
}

.form-group input {
  /* border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important; */
  border: none;
}

.form-group select {
  /* border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important; */
  border: none !important;
  width: 100%;
}

.newselect {
  /* border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important; */
  border: none !important;
  width: 100%;
}

.coursecol {
  padding: 0 3px !important;
}

.col-md-4 {
  padding: 0 3px !important;
}

.col-md-3 {
  padding: 0 5px !important;
}

.col-md-2 {
  padding: 0 5px !important;
}

.col-md-6 {
  padding: 0 3px !important;
}

.col-md-8 {
  padding: 0 3px !important;
}

.col-md-9 {
  padding: 0 3px !important;
}

.col-md-12 {
  padding: 0 3px !important;
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 14px !important;
  letter-spacing: 0.02em !important;
}

.providerpadding {
  padding: 0 3px !important;
}

/* .col-md-6 {
  padding: 0 5px !important;
} */
.nav-tabs .nav-link {
  color: #495057 !important;
  font-weight: bold;
}

.nav-tabs .nav-link.active {
  /* color: cornflowerblue !important; */
  color: #17b978 !important;
  font-weight: bold;
}

/* .ck.ck-editor__editable_inline> :first-child {
  font-size: 23px !important;
} */

.branch_buttonrow {
  margin: 20px 5px !important;
  float: right;
}

.master.MuiSelect-select {
  text-align: right !important;
}

.dashboardp {
  margin-bottom: 0 !important;
  margin-top: 5px !important;
}

.dashboardh {
  color: #6D7290 !important;
  box-sizing: border-box !important;
  font-size: 15px !important;
  padding: 0.5rem !important;
  border-radius: 6px !important;
  background-color: transparent !important;
  border: none !important;
  /* text-transform: capitalize !important; */
}

.location_status {
  margin-bottom: 0 !important;
  font-weight: bold;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.maindashboard_select .MuiInput-underline:before {
  border-bottom: 1px solid !important;
}

label {
  color: #2a4184 !important;
}

.quotation_input .MuiInputBase-input {
  text-align: left !important;
}

.MuiListItemIcon-root {
  min-width: 40px !important;
}

.makeStyles-drawerClose-70 {
  width: 60px !important;
}

.notes_dummy .MuiSelect-select {
  /* width: 90px; */
  border-bottom: 1px solid !important;
}

.dummy3_attachment_radio .MuiFormControl-root {
  display: contents !important;
}

.clientgeneralinvoicetables .MuiTableCell-root {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.MuiDrawer-paper {
  overflow-y: scroll !important;
}

.MuiDrawer-paper::-webkit-scrollbar {
  width: 10px !important;
}

.MuiDrawer-paper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.MuiDrawer-paper::-webkit-scrollbar-thumb {
  /* background: rgb(0, 174, 255) !important; */
  background: #17b978 !important;
  border-radius: 10px;
}

.newdashboard_icon .MuiListItem-root.Mui-selected {
  background-color: #17b978 !important;

  /* background-color: rgb(0, 174, 255) !important; */
  /* background-color: lightblue !important; */
}

.MuiTypography-colorPrimary {
  color: #071A52 !important;
}

.MuiTypography-h3 {
  font-size: 50px !important;
  color: #071a52 !important;
}

.MuiSvgIcon-colorPrimary {
  color: #17b978 !important;
}

.card-body {
  padding: 20px !important;
}

.card-body-sangam {
  padding: 20px !important;
}

.card-body-san {
  padding: 20px !important;
}

.client_datefilterinput {
  padding: 0 20px !important;
}

.quotation_margin_row {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.adminusers_row .col {
  padding: 0 !important;
}

.MuiInput-root {
  /* border: 1px solid #ced4da !important; */
  border: none !important;
  background-color: #f5f5f9 !important;
  font-size: 14px !important;
  font-family: "Source Sans Pro", sans-serif !important;
}

.makeStyles-formControl-21 {
  border: none !important;
}

.WithStyles\(ForwardRef\(AccordionSummary\)\)-root-74 {
  background-color: "#f5f5f9" !important;
}

.MuiAppBar-colorPrimary {
  color: #0000008A !important;
  background-color: #fff !important;
}

.newdashboard_icon .MuiTypography-body1 {
  color: #e0e1ea;
}

.newdashboard_icon {
  background-color: #262a2e !important;
}

.newdashboard_icon .MuiSvgIcon-root {
  color: #e0e1ea;
}

.WithStyles\(ForwardRef\(AccordionSummary\)\)-root-74 {
  background-color: #f5f5f9 !important;
}

.newdashboard_backgroundcolor .MuiPaper-root {
  background-color: #262a2e !important;
}

#myForm>div {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.fonticonpositioning {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.4em;
  color: #94b2c6;
  cursor: pointer;
}

.rct-options {
  height: 50px !important;
  position: absolute !important;
  left: -34px !important;
  top: -30px !important;
}

.roleabsoluteposition {
  height: 50px;
  width: 50px !important;
  position: absolute !important;
  top: -30px;
  left: 25px;
}

.provideradmin {
  margin: 10px 0 !important;
}

.form_inner_admin hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.rolesrowalignment {
  margin-left: 35px !important;
}

.provider_containertitle {
  font-size: 20px;
  color: #071A52;
  margin-bottom: -5px !important;
  font-weight: bold;
}

.provider_containertitle hr {
  margin-bottom: -5px !important;
}

/* .ReactModal__Overlay--after-open {
  background-color: #00000705 !important;
} */
/* .ReactModal__Overlay--after-open {
  /* background-color: transparent !important; 
} */
.master .MuiInput-root {
  border-radius: 0.25rem !important;
  height: 45px !important;
}

.area_rowslable {
  /* margin: 0 10px !important; */
  /* font-weight: bold; */
  /* color: #071A52 !important; */
  color: #212529 !important;
  margin-bottom: 0.0rem;
  font-weight: bold;
  float: left;
}

.area_rowslable_skill {
  /* margin: 0 10px !important; */
  font-weight: bold;
  color: #071A52 !important;
  float: left;
  /* color: #212529 !important; */
}

.area_rowslable_my {
  margin-bottom: 0.5rem;
  font-size: 14px !important;
  color: #212529 !important;
  font-weight: bold;
  float: left;
}

.area_rowslable_new {
  font-size: 14px !important;
  color: #212529 !important;
  margin-bottom: 0.0rem;
}

.select__control {
  height: "16px" !important;
}

.css-13cymwt-control {
  height: "16px" !important;
}

.select__value-container {
  height: "16px" !important;
}

.select__value-container--is-multi {
  height: "16px" !important;
}

.css-1fdsijx-ValueContainer {
  height: "16px" !important;
}


.form-control {
  font-size: 14px !important;
  background-color: #f5f5f9;
  border: none !important;
  color: #6d7290 !important;
}

.area_rowslableRight {
  margin: 13 10px !important;
  font-weight: bold;
  color: #2a4184;
  float: right;
}

.master .MuiFormControl-root {
  margin-top: 0px !important;
}

.formfooterrecordstyle {
  margin-top: 20px;
  text-align: left !important;
  color: #6D7290 !important;
}

.viewproviderptag {
  margin-bottom: 0px !important;
}

.providerreport {
  margin: 30px 0 !important;
}

.MuiCheckbox-root {
  padding: 9px 2px 9px 9px !important;
}

.workflowcol .col {
  padding: 0 5px !important;
}

.viewworkflowheader {
  width: 72vw !important;
}

.viewworkflowheader2 {
  width: 66vw !important;
}

/* .dummy_contentright .MuiAppBar-root:first-child {
  width: 66vw !important;
} */
.CircularProgressbar .CircularProgressbar-path {
  stroke: orange !important;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: orange !important;
}

.accordiondetails {
  margin-right: 5px !important;
}

.notesformmgroup {
  margin-top: 0 !important;
}

/* .select-currency>div>input {
  height: 40px !important;
  width: 100% !important;
  background-color: #f5f5f9 !important;
} */

.MuiListItem-root {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

#dummy2_middlecontent .MuiTab-textColorInherit.Mui-selected {
  background-color: #3f51bf !important;
  color: #fff !important;
}

#dummy2_middlecontent .MuiAppBar-root {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #cdc9c9 !important;
  border-bottom: 1px solid !important;
}

#dummy2_middlecontent .MuiTab-root {
  border-right: 1px solid !important;
}

.dummy_contentright .Mui-selected {
  background-color: #fff !important;
  color: #6D7290 !important;
}

.Mui-selected:hover {
  color: #17b978 !important;
}

.dummy_contentright .MuiAppBar-root {
  /* color: rgba(0, 0, 0, 0.87) !important; */
  /* background-color: #cdc9c9 !important; */
  background-color: #fff !important;
  /* border-bottom: 1px solid !important; */
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1pxsolidrgba(0, 0, 0, .2);
  border-radius: 0.3rem;
  outline: 0;
}

.PrivateTabIndicator-colorPrimary-63 {
  background-color: #17b978 !important;
}

#course_view_topcontent .Mui-selected {
  background-color: #3f51bf !important;
  color: #fff !important;
}

#course_view_topcontent .MuiAppBar-root {
  color: rgba(0, 0, 0, 0.87) !important;
  /* background-color: #cdc9c9 !important; */
  border-bottom: 1px solid !important;
}

/* #course_view_topcontent .MuiTab-root {
  border-right: 1px solid !important;
} */

.nobordertab {
  flex: 1 !important;
}

.ck-content {
  min-height: 250px !important;
}

.workflowdashboard_chart {
  /* min-height: 150px; */
  background: white;
  box-shadow: 1px 0px 3px 0px rgb(0 0 0 / 75%);
  overflow: hidden;
  margin: 5px;
  flex: 0.55;
}

.enquirynotesdashboard_note {
  background: white;
  box-shadow: 1px 0px 3px 0px rgb(0 0 0 / 75%);
  padding: 10px !important;
  overflow: auto;
  height: 220px;
  background-color: #fff;
  position: relative;
  text-align: left;
}

.workflowdashboard_clientnote {
  background: white;
  /* box-shadow: 1px 0px 3px 0px rgb(0 0 0 / 75%); */
  padding: 8px !important;
  /* overflow: auto;
  height: 100px; */
  background-color: #fff;
  position: relative;
}

.workflowdashboard_absolute {
  width: 98%;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  min-height: 38px;
  background: #eeecee;
  padding: 0 20px;
  box-shadow: 1px 0px 3px 0px rgb(0 0 0 / 75%);
  align-items: center;
}

.workflowdashboard_fa-ellipsis {
  cursor: pointer;
  padding: 5px 10px;
  transition: all 0.3s ease;
}

.workflowdashboard_fa-ellipsis:hover {
  background-color: #17b978;
  /* border-radius: 50%; */
}

.newdashboard_icon .MuiTypography-body1 {
  line-height: 0.8 !important;
}

.dummymargin {
  margin-bottom: 0 !important;
}

.collegeimage {
  width: 100px !important;
  height: 84px !important;
  border-radius: 0 !important;
}

.leadellipsis {
  width: 200px !important;
  white-space: nowrap;
  overflow: hidden;
  /* This add an ellipsis i.e ... if the text increases too long */
  text-overflow: ellipsis;
  /* position: relative; */
  display: block;
  height: 70px;
}

.leadellipsisspan {
  position: absolute;
  cursor: pointer;
  left: 10px;
  color: #ffc900;
  font-weight: bold;
  bottom: 0px;
}

.providerlist_logo {
  background-color: orange !important;
  width: 70px !important;
  height: 50px !important;
  border-radius: 0px !important;
  position: relative !important;
}

.no_logo {
  background-color: orange !important;
  width: 120px !important;
  height: 120px !important;
  border-radius: 40% !important;
  position: relative !important;
  margin-left: 50px !important;
}

.workflowdashboarddropdown {
  min-width: 80px !important;
}

.sang_workflowdashboarddropdown {
  min-width: 140px !important;
}

.workflowdashboarddropdown p {
  padding-left: 20px;
  margin-bottom: 0 !important;
  cursor: pointer;
}

.sang_workflowdashboarddropdown p {
  padding-left: 20px;
  margin-bottom: 0 !important;
  cursor: pointer;
}

.sangam_workflowdashboarddropdown {
  min-width: 180px !important;
}

.dropdown-menu.show {
  width: max-content;
}

.printinvoice_divlisting {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
}

.printinvoice_divlistingtextfirst {
  font-weight: bolder;
  flex: 0.7;
  margin-left: 20px;
}

.nomarginbottom {
  margin-bottom: 0 !important;
}

.printinvoice_divlistingtextsecond {
  font-weight: bolder;
  flex: 0.3 1;
  display: flex;
  justify-content: end;
  margin-right: 10px;
}

.printinvoice_paymentdetails {
  height: 50px;
  display: flex;
  align-items: center;
}

.printinvoice_headerrightbox {
  display: flex;
  justify-content: space-between;
}

.printinvoice_headerrightbox h6 {
  font-size: 20px;
}

.printinvoice_headerrightboxnew {
  display: flex;
  margin-bottom: 5px;
}

.print_middle thead tr {
  background-color: #737373;
  /* background-color: rgba(0, 0, 0, 0.4) !important; */
}

.print_middle .MuiTableCell-root {
  border: none !important;
  font-size: 20px;
}

.print_middle thead tr th {
  color: white;
}

.printinvoice_tr tr {
  background-color: #d9d9d9 !important;
}

.printinvoice_tr tr th {
  color: #000 !important;
}

.printinvoice_horiline {
  border-top: 2px solid #737272 !important;
}

.printinvoice_headerrightboxnew p {
  font-weight: bold;
  font-size: 20px;
}

.print_invoicetotaldue p {
  font-weight: bolder;
  font-size: 25px;
}

.printinvoice_headerrightboxtop {
  right: 30px;
  margin: 10px;
  /* border: 1px solid; */
  padding-top: 20px;
  padding-bottom: 20px;
}

.printinvoice_header {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
}

.dateptag {
  position: absolute;
  top: 40px;
  z-index: 1000;
  left: 10px;
}

.dummy2_datepicker {
  margin-right: 7px !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.addinvoice_datedetail .react-datepicker-wrapper {
  width: 100%;
  flex: 0.5;
}

.invoicecurrency .react-datepicker-wrapper {
  width: auto;
}

.invoicecurrency .form-control {
  width: 200px !important;
}

.dummy2_datepicker .dateptag {
  top: 11px;
}

.clientgeneralinvoicetables .dateptag {
  top: 23px;
}

.dummy2_datepicker .col-md-9 {
  padding: 0 !important;
}

.dummy2_datepicker .col-md-3 {
  padding: 0 !important;
}

.react-datepicker-wrapper input {
  width: 100%;
  border-radius: 4px !important;
  height: 37px;
  background-color: #f5f5f9 !important;
  border: none !important;
}

.datepickerrangevaluetext {
  /* border: 1px solid #ccc; */
  padding: 9px;
  border-radius: 3px;
  margin-bottom: 0 !important;
  cursor: pointer;
  height: 40px !important;
}

.datepickerrangevaluetext2 {
  /* border: 1px solid #ccc; */
  padding: 6px;
  border-radius: 3px;
  margin-bottom: 0 !important;
  cursor: pointer;
  height: 45px !important;
}

/* .clearsearchclass {
  display: flex !important;
  align-items: center !important;
} */
.clearsearchclass a {
  cursor: pointer;
}

.clearsearchclass input {
  background-color: #f0f2f5 !important;
  height: 45px;
}

.dashboardnodataptag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62%;
}

.printinvoice_footerimage {
  position: absolute;
  z-index: 0;
  height: 250px;
  width: 330px;
  bottom: 10px;
  opacity: 0.2;
  left: 10px;
}


.amount_rowslabel {
  margin: 14px !important;
  font-weight: bold;
  color: #2a4184;
  float: right;
}

._2iA8p44d0WZ-WqRBGcAuEV {
  background-color: #f5f5f9 !important;
  height: 40px !important;
  border: none !important;
}

._3vt7_Mh4hRCFbp__dFqBCI input {
  height: 40px !important;
  margin-top: -5px !important;
}

.nav-tabs {
  border: none !important;
}

.react-autosuggest__suggestions-container--open{
  text-align: left;
}