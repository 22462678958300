.btm-comparebox {
	background: #fff;
	padding: 20px;
	box-sizing: border-box;
	width: 100%;
	position: fixed;
	left: 0px;
	bottom: 0px;
	border-top: 1px solid rgba(0, 0, 0, 0.12);
	z-index: 9999;
	/* display:none; */
}

.btm-comparebox .container {
	max-width: 800px;
}

.btm-comparebox .btn.bordered {
	background: #fff3e8 !important;
	border-radius: 30px !important;
	color: #fe8518 !important;
	border: 0px !important;
}

.coursedtl-intrestbox .btn-outlined {
	text-align: center !important;
	background: none !important;
	border: 1px solid #fe8518 !important;
	color: #fe8518 !important;
	width: 100% !important;

	height: 56px !important;
	font-weight: 600 !important;
	font-size: 20px !important;
}

.comparebox-item {
	border: 1px dashed rgb(191, 191, 191);
	border-radius: 2px;
	padding: 5px;
	min-height: 103px;
	display: flex;
	align-items: center;
}

.comparebox-item img {
	width: 100%;
	height: inherit !important;
}

.comparebox-icon {
	fill: #de5753;
	color: #de5753;
	width: 16px !important;
	cursor: pointer;
	position: absolute;
	right: 17px;
	top: 0px;
}

.account-username {
	font-size: 12px !important;
	color: #999da8 !important;
	font-weight: 600 !important;
	padding-bottom: 20px !important;
}

label {
	font-size: 14px;
	font-weight: 600;
}

.text-red {
	color: #de5753;
}

.btn-green,
.btn-primary:hover,
.modal-footer .btn-primary {
	background-color: #00ca8e !important;
	border-color: #00ca8e !important;
	color: #fff !important;
}

.modal-body h4 {
	font-size: 16px;
	font-weight: 600;
}

.accountbody_tabnav {
	margin-bottom: 20px !important;
	border: 0px !important;
}

.mainaccount_right .accountbody_tabnav .nav-link {
	font-size: 16px !important;
	background-color: #fff !important;
	color: #07122c !important;
	border-radius: 8px !important;
	margin-right: 16px !important;
}

.mainaccount_right .accountbody_tabnav .nav-item .active {
	background-color: #07122c !important;
	color: #ffffff !important;
	border-color: #fff;
	border: 0px !important;
}

.accountenquiry p.MuiTypography-body1 {
	color: #000 !important;
}

.accountbody_subtabnav {
	margin-bottom: 0px !important;
	border: 0px !important;
	border-bottom: 1px solid #dce1ed !important;
	padding-bottom: 2px;
	padding-left: 10px !important;
}

.mainaccount_right .accountbody_subtabnav .nav-link {
	font-size: 12px !important;
	background-color: #fff !important;
	color: #999da8 !important;
	border-radius: 0px !important;
	margin-right: 16px !important;
	border-bottom: 2px solid #fff !important;
}

.mainaccount_right .accountbody_subtabnav .nav-item .active {
	background-color: #fff !important;
	color: #00ca8e !important;
	border-color: #fff;
	border-bottom: 2px solid #00ca8e !important;
	font-weight: 600 !important;
}

.account-username span {
	font-size: 16px !important;
	color: #07122c !important;
	font-weight: 600 !important;
}

.cursor-pointer {
	cursor: pointer;
}

.btn.btn-primary {
	color: #fff;
	background-color: #fe8518;
	border-color: #fe8518;
}

.login-leftbox {
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
}

.eye-icon {
	position: absolute;
	right: 25px;
	top: 45px;
}

.login-leftbox h6 {
	font-weight: 700;
	font-size: 20px;
	color: #ffffff;
}

.login .login-leftbox h2 {
	font-weight: 700;
	font-size: 44px;
	line-height: 55px;
	color: #ffffff;
}

.login-rightform {
	background: #fff;
	height: 100vh;
	box-sizing: border-box;
	overflow: auto;
	max-width: 800px;


}

.row {
	display: flex;
	/* justify-content: space-evenly; */
}

.login-top-label-box {
	font-weight: 600;
	font-size: 16px;
	color: #07122c;
}

.login-top-label-box-inn {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.login-top-label-circle {
	background: #ffffff;
	border: 1px solid #dce1ed;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	margin-right: 10px;
	color: #999da8;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.login-top-label-selected {}

.login-top-label-selected {
	color: #fe8518;
}

.login-top-label-selected .login-top-label-circle {
	background: #fff3e8;
	color: #fe8518;
	border-color: #fff3e8;
}

.login-top-label-done {}

.login-top-label-done .login-top-label-circle {
	background: #fe8518;
	color: #fff;
	border-color: #fe8518;
}

.login-top-label-border {
	border-top: 1px dashed #dce1ed;
	margin-top: 20px;
}

.login-top-label-border-done {
	border-color: #fe8518;
}

.login-form-btn {
	background: #00ca8e !important;
	border-color: #00ca8e !important;
	width: 130px !important;
	font-weight: 600 !important;
}

.login-form-btn:hover {
	background: #fe8518 !important;
	border-color: #fe8518 !important;
}

.login-rightform-inn {
	padding: 30px 50px;
}

.login .login-rightform-inn h2 {
	font-weight: 700;
	font-size: 24px;
	color: #07122c;
}

.loginbox {
	padding-top: 92px;
	overflow: hidden;
}

.login-rightform-inn .form-control,
body .form-control {
	background: #f5f6f8;
	border-color: #f5f6f8;
	height: inherit !important;
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
	font-size: 16px;
}

.search-input-navbar-form {
	max-width: 225px;
}

.login-rightform-inn label {
	font-weight: 600;
	font-size: 14px;
	float: left;
}

.login-bg.login.coursecompare_bodymaincontainer {
	/* background: url("./assets/images/login-bg.jpg") no-repeat center center; */
	background-size: cover;
	margin: 0px !important;
	height: 100vh;
	background-color: #071a52;

}

.login-bg .login_container {
	margin: 0 auto !important;
	padding: 0px 15px !important;
	box-shadow: unset !important;
	border-radius: 0px !important;
}

.compare-course-apply h5 {
	width: 100% !important;
	display: block !important;
	background: #00ca8e !important;
	border-radius: 8px !important;
	color: #fff;
	padding-top: 15px !important;
	padding-bottom: 15px !important;
	font-weight: 600 !important;
	font-size: 16px !important;
}

.compare-course-roundedbox {
	border: 1px solid #dce1ed;
	border-radius: 8px;
	width: 100%;
	margin-bottom: 20px;
	padding: 10px;
	font-size: 16px;
}

.compare-course-roundedbox a {
	color: #fe8518;
	text-decoration: underline;
}

.coursesearchbody_bordernotopbottom_input {
	overflow: hidden;
	height: 50px;
}

.MuiTypography-root.MuiTypography-colorPrimary {
	color: #fe8518;
}

.compare-topbox-text {
	margin: 0px 5px;
	border-left: 1px solid #dce1ed;
	border-right: 1px solid #dce1ed;
	padding: 15px 10px;
	font-size: 16px;
	color: #07122c;
}

.compare-topbox-text p {
	font-size: 16px;
	padding: 0px;
	margin: 0px;
	color: #07122c;
}





.compare-topbox-btm {
	padding: 0px 10px;
	margin: 0px 5px;
	border-left: 1px solid #dce1ed;
	border-right: 1px solid #dce1ed;
	border-bottom: 1px solid #dce1ed;
	-webkit-border-radius: 0 0 8px 8px;
	border-radius: 0 0 8px 8px;
}

.compare-topbox-btm h6 {
	color: #07122c;
	font-weight: 400;
	font-size: 12px;
}

.compare-headlines {
	text-align: center;
}

.compare-logobox {
	margin: 0px 5px;
	border-left: 1px solid #dce1ed;
	border-right: 1px solid #dce1ed;
	padding: 0px 10px;
}

.compare-headlines h5 {
	color: #07122c;
	font-weight: 600;
	font-size: 20px;
	font-family: 'Sora', sans-serif !important;
	display: inline-block;
	padding: 0px 0px 10px;
	margin: 0px 0px 20px 0px;
	border-bottom: 2px solid #00ca8e;
}

.old-menu {
	position: absolute;
	z-index: 9999;
	top: 200px;
	display: none;
}

.compare-clearsearch {
	float: right;
	margin-top: -50px;
	margin-right: 15px;
}

.compare-clearsearch .btn.coursesearchbody_comparetext {
	color: #fe8518 !important;
	background: none !important;
	text-decoration: underline !important;
	font-weight: 700 !important;
	font-size: 18px !important;
}

.course-searchrightbar .MuiPaginationItem-textPrimary.Mui-selected {
	background: #fff3e8 !important;
	color: #fe8518 !important;
}

.course-searchrightbarlast.centerofcontainer {
	justify-content: space-between !important;
	margin: 13px 0px 20px;
}

.course-searchrightbar h5 {
	color: #07122c !important;
	font-weight: 700 !important;
	font-size: 20px !important;
}

.coursesearchbody_bordernotopbottom p {
	font-weight: 600;
	font-size: 16px;
	color: #07122c;
}

.course-search-card-smlabel {
	font-weight: 400 !important;
	font-size: 12px !important;
	font-family: 'Sora', sans-serif !important;

	color: #07122c !important;
}

.course-searchleftbar {
	border-right: 1px solid #dce1ed;
	background: #fff;
}

.course-searchleftbar .MuiAccordion-root.Mui-expanded,
.course-searchleftbar .MuiPaper-elevation1 {
	border: 0px !important;
	border-bottom: 1px solid #dce1ed !important;
	margin: 0px !important;
	box-shadow: none !important;
}

.course-searchleftbar .MuiAccordion-root:before {
	display: none !important;
}

.course-searchleftbar .makeStyles-heading-2 {
	color: #07122c !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	font-family: 'Sora', sans-serif;
}

.course-searchleftbar .MuiAccordionSummary-content {
	margin: 20px 0 !important;
}

.readonlyformcontrol {
	background-color: #b8bec4 !important;
	opacity: 1;
	border: none !important;
	padding-left: 40px;
}

input {
	width: 100%;
	background: #fff none repeat scroll 0 0;
	border: 1px solid #aaa;
	border-radius: 8px !important;
	height: 40px !important;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

.filtersearchheader {
	width: 90%;
	display: flex;
	/* margin-top: -140px;
  margin-bottom: 41px; */
	margin: -135px auto 25px;
	justify-content: center;
	text-align: center;
	border: 3px solid rgba(1, 9, 39, 0.7);
}

.filtersearchheader_search {
	font-size: 14px;
	background: #f7f7f7;
	color: #d7d7d7;
	height: 40px;
	width: 93%;
	/* min-width: 370px; */
	color: #454545;
	padding: 0px 13px;
	padding-right: 115px;
	outline: none;
	border-radius: 0 !important;
}

.filtersearchheader_button {
	width: 108px;
	height: 40px;
	background: #2b58a5;
	border: 1px solid #1d458a;
	color: #fff;
	font-size: 14px;
	line-height: 18px;
	cursor: pointer;
}

@media only screen and (max-width: 768px) {
	.videocarouselshow {
		display: none;
	}
}

.accountmessage {
	padding: 40px;
	margin-bottom: 20px;
}

.accountmessage .row {
	margin: 10px 0;
	padding: 10px 0;
	border-bottom: 1px solid #ddd;
}

.accountmessage .row label {
	color: #2d5f9f;
}

.errormessage {
	color: rgb(255, 0, 0);
	text-align: left;
	/* font-weight: bolder; */
	padding: 2px;
}

.course_desc {
	width: 100%;
	background: #f5f5f5;
	padding: 15px;
	border-radius: 5px;
}

.course_desc h4 {
	font-size: 18px;
	text-transform: uppercase;
}

.course_desc p {
	line-height: 24px;
	color: #444;
}

.bookmark_list {
	width: 100%;
	border-bottom: 1px solid #e3e3e3;
	padding: 20px;
	transition: all 0.5s ease;
	border-radius: 5px;
}

.bookmark_list:hover {
	background: #f5f5f5;
}

.coursecompare_heading {
	border-bottom: 1px solid #2561ae;
	font-size: 40px;
	line-height: 70px;
	font-weight: 700;
	padding: 0;
	color: #fff;
}

.coursecompare {
	margin: 0px !important;
}

.coursecompare_headingbutton {
	display: inline-block;
	text-transform: uppercase;
	font-size: 18px;
	color: #2b58a5;
	font-weight: 600;
	line-height: 24px;
	border: 3px solid #2b58a5;
	background: #fff;
	padding: 9px 99px 7px 99px;
	transition: all 0.5s ease;
}

.coursecompare_headingbutton:hover {
	color: #fff;
	background: #2b58a5;
}

.coursecompareoneheader {
	background: #2b58a5;
	color: #fff;
	font-size: 24px;
	line-height: 30px;
	font-weight: 600;
	text-transform: uppercase;
	padding-left: 47px;
	border-right: 1px solid #fff;
	padding-top: 28px;
	padding-bottom: 26px;
	min-height: 145px;
}

.coursecomparetwoheader {
	background: #d83e4a;
	border-right: none;
	color: #fff;
	font-size: 24px;
	line-height: 30px;
	font-weight: 600;
	text-transform: uppercase;
	padding-left: 47px;
	min-height: 145px;
	padding-top: 28px;
	padding-bottom: 26px;
}

.coursecomparethreeheader {
	background: #2b58a5;
	color: #fff;
	font-size: 24px;
	line-height: 30px;
	font-weight: 600;
	text-transform: uppercase;
	padding-left: 47px;
	border-left: 1px solid #fff;
	padding-top: 28px;
	padding-bottom: 26px;
	min-height: 145px;
}

.coursecompareoneheader_h4 {
	font-size: 18px;
	font-weight: bolder;
	line-height: 24px;
	color: #2b58a5;
	width: 100%;
	float: left;
	background: #f2f1f2;
	margin: 0;
	padding: 13px 0 12px 46px;
	height: 60px;
}

.coursecomparepadding {
	padding: 0 !important;
}

.course_box {
	margin: 0px 100px !important;
	padding: 0;
	list-style: none;
	border: 1px solid #9aa5d3;
	border-top: none;
	margin-bottom: 50px !important;
}

.desc_course_area {
	padding: 21px 48px 25px;
	border-right: 1px solid #9aa5d3;
}

.coursecomparemarginheading {
	margin: 0 100px !important;
}

input[type='checkbox'] {
	cursor: pointer;
}

.course_button {
	color: blue !important;
	border: 1px solid !important;
}

.coursedropdown {
	margin-top: -20px !important;
}

.accountbody_responsivenav {
	flex-wrap: nowrap !important;
	overflow-x: auto;
	overflow-y: clip;
}

.slick-next:before {
	color: black !important;
}

.slick-prev:before {
	color: black !important;
}

.providerBody .nav-item {
	width: 209px !important;
}

.courseBody {
	padding: 0 !important;
}

.courseBody .tab-content {
	padding: 20px;
}

.courseBodynav .nav-item {
	width: 33.3% !important;
}

.fonticonpositioningVerify {
	position: absolute;
	right: 10px;
	top: 26px;
	transform: translateY(-50%);
	font-size: 1.4em;
	color: #94b2c6;
	cursor: pointer;
}

/* New CSS Start */

.homepagebackgroundimage {
	/* background: url(./assets/images/ISWBanner.jpg); */
	/* background: url(./../../assets/images/ISWImage.jpg); */
	background-color: #cccccc;
	height: 500px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.homepage_selectioncontainer {
	position: relative;
	z-index: 999;
}

.homepage_formcontainer {
	/* padding: 0 40px; */
}

.homepage_formcontainer label {
	width: 100%;
	color: #224289;
	font-weight: 500;
	text-align: center;
	font-size: 20px;
}

.homepage_horizontalline {
	width: 58px;
	margin: 20px auto !important;
	height: 7px;
	border-width: 0;
	color: #55aad3;
	background-color: #55aad3;
}

.selecttag2 {
	appearance: none;
	/* background: url(./../../assets/images/registerselecttriangle.png) no-repeat; */
	background-position: 97% 58%;
}

.homepage_icon {
	position: absolute;
	top: 13px;
	right: 11px;
	transform: scale(2.5);
	color: #7de1c3;
}

.homepageselect_icon {
	position: absolute;
	top: 16px;
	right: 7px;
	transform: scale(2.5);
	color: #7de1c3;
}

.homepage_autoselect div:nth-of-type(1) {
	border-radius: 30px !important;
}

.homepage_autoselect span:nth-of-type(1) {
	display: none !important;
}

.homepage_searchbutton {
	background: #7de1c3 !important;
	border-radius: 30px !important;
	margin: 30px 0;
	margin-top: 60px;
	font-size: 20px !important;
	padding: 6px 47px !important;
}

.homepageerror {
	color: rgb(255, 0, 0);
	font-size: 16px;
	font-weight: normal;
	margin-bottom: 0 !important;
}

.coursesrc-title {
	/* background: url(./../../assets/images/contact-search-title.jpg) no-repeat; */
	width: 100%;
	min-height: 354px;
	background-size: cover;
	display: flex;
	align-items: center;
}

.coursesearchbody_inputcontainer {
	padding: 50px;
	/* background-image: linear-gradient(to right, #55aad3, #224289); */
	background-color: #7de1b7;
}

.coursesearchbody_searchinput {
	border-radius: 19px !important;
	padding: 25px !important;
	flex: 0.9;
	margin-right: 20px;
}

.coursesearchbody_searchicon {
	padding: 6px;
	background-color: #15be8c;
	transform: scale(2);
	margin-top: 10px;
	border-radius: 10px;
	margin-left: 10px;
	transition: all 0.4s ease;
}

.coursesearchbody_searchicon:hover {
	cursor: pointer;
	background-color: #14a77a;
}

.coursesearchbody_counttext {
	color: #fe8518;
	font-size: 18px;
}

@media screen and (max-width: 1200px) {
	.coursesearchbody_counttext {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.coursesearchbody_comparetext {
		margin-top: 10px;
		margin-bottom: 10px;
	}
}

.coursesearchbody_comparetext {
	background-color: #7de1c3 !important;
	padding: 7px 20px !important;
	border-radius: 10px !important;
	margin-bottom: 10px;
}

.coursesearchbody_coursedetailcontainer {
	border: 1px solid #dce1ed;
	border-radius: 12px;
	margin-bottom: 15px;
}

.coursesearchbody_bordernotop {
	border-top: none;
	height: 80px;
}

.coursesearchbody_bordernotopbottom {
	border-top: none;
	border-bottom: none;
	height: 80px;
}

.colorlightblue {
	color: #55aad3;
}

.centerofcontainer {
	justify-content: center !important;
	display: flex !important;
	flex-direction: column !important;
}

.starcontainer {
	color: gold;
}

.coursesearchbody_overviewbutton {
	height: 35px !important;

	color: #fe8518 !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	background: #fff3e8 !important;
	border-radius: 300px !important;
	margin-top: 20px;
}

.coursesearchbody_applynowbutton {
	background: #00ca8e !important;
	border-radius: 8px !important;
	margin: 5px !important;
	height: inherit !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	color: #ffffff !important;
	padding-top: 8px !important;
	padding-bottom: 10px !important;
}

.coursesearchbody_comparebutton {
	background: #fff3e8 !important;
	border-radius: 300px !important;
	color: #fe8518 !important;
	font-weight: 600 !important;
	font-size: 14px !important;
}

.learnmoretext {
	text-align: center;
	color: #6aa76a;
	border-bottom: 1px solid;
	width: 100px;
	transition: all 0.4s ease;
	margin-bottom: 0 !important;
}

.learnmoretext:hover {
	cursor: pointer;
	color: rgb(25, 189, 25);
}

.coursefiltercount {
	padding: 2px 7px 2px;
	background: #fff3e8;
	border-radius: 10px;
	color: #fe8518;
	font-size: 12px;
	margin-top: 10px;
	margin-right: 10px;
	height: 20px;
}

.navigatetocourse {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	transition: all 0.5s ease;
}

.coursecompare_nameellipsis {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	transition: all 0.5s ease;
}

.navigatetocourse:hover {
	cursor: pointer;
	color: #fe8518;
}

.coursebodyleft_locationcontainer label {
	color: #fe8518;
	margin: 0 15px;
}

.coursebodyleft_locationfromcontainer {
	width: 90% !important;
	margin-left: 15px;
	margin-right: 15px;
	margin-bottom: 20px;
}

.coursebodyleft_selecttag {
	position: absolute;
	top: 7px;
	right: 23px;
	transform: scale(2);
	color: #7de1c3;
}

.courseheaderbody_overviewbutton {
	background-color: #55aad3 !important;
	border-radius: 5px !important;
	padding: 10px 30px !important;
}

.courseheaderbody_leftcontainer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 20px;
	background-color: #55aad3;
	height: 400px;
}

.courseheaderbody_leftcontainer>div {
	width: 200px;
	height: 200px;
	border-radius: 50%;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
}

.courseheaderbody_leftcontainer img {
	width: 120px;
}

.courseheaderbody_leftcontainer p {
	font-size: 20px;
	text-align: center;
}

.courseheaderbody_rightcontainer {
	/* background: url(./../../assets/images/CourseDetailBanner.jpg) no-repeat; */
	background-position: left top;
	background-size: 100% auto !important;
	height: 400px;
	padding-right: 0 !important;
}

.courseheaderbody_shadowrightcontainer {
	height: 400px;
	background: rgb(0 80 130 / 50%);
	padding: 40px;
}

.courseheaderbody_shadowrightcontainer .row p {
	color: white;
}

.courseheaderbody_icontainer {
	border: 1px solid white;
	border-radius: 50%;
	height: 35px;
	padding: 5px;
	margin-right: 5px;
}

.courseheaderbody_shadowrightcontainer i {
	color: white;
	margin-right: 6px;
	transform: scale(1.5);
	margin-left: 5px;
}

.coursebodylower_leftuppercontainer {
	/* background: url(./../../assets/images/coursebanner2.png); */
	background-color: #cccccc;
	height: 210px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 15px;
}

.coursebodylower_leftuppercontainer button,
.coursebodylower_leftlowercontainer button {
	background-color: #fff;
	padding: 5px 10px !important;
	margin-bottom: 10px;
}

.coursebodylower_leftlowercontainer {
	/* background: url(./../../assets/images/coursebanner1.png); */
	background-color: #cccccc;
	height: 230px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	margin-top: 20px;
	border-radius: 15px;
}

.departmentnav a {
	/* width: 279px !important; */
	text-align: center;
	background: #2561ae;
	font-size: 14px;
	line-height: 18px;
	border: 1px solid #8e9ed0;
	left: 1px;
	margin-left: -1px;
	position: relative;
	vertical-align: bottom;
	cursor: pointer;
	background: #55aad3;
	color: #fff;
	padding: 16px 11px;
	border-right: 2px solid #fff !important;
	margin-bottom: 5px;
	font-weight: bold;
}

.CourseOverviewheader {
	color: #224289;
	font-weight: bold;
	font-size: 24px;
}

.providerdepartmentnav .nav-link.active {
	background: #224289 !important;
}

.providerdepartmentnav a {
	text-align: center;
	background: #2561ae;
	font-size: 14px;
	line-height: 18px;
	border: 1px solid #8e9ed0;
	left: 1px;
	margin-left: -1px;
	position: relative;
	vertical-align: bottom;
	cursor: pointer;
	background: #55aad3;
	color: #fff;
	padding: 16px 11px;
	border-right: 3px solid #fff !important;
	margin-bottom: 5px;
	font-weight: bold;
}

.departmentnav .nav-link.active {
	background: #224289 !important;
}

.coursenavlink a {
	border-radius: 0 !important;
}

.coursetabcontent {
	background-color: #f5f5f9 !important;
}

.coursecompare_coursenamecontainer {
	margin-top: 10px;
	box-shadow: 0 0 20px #ccc;
	border-radius: 10px;
}

.coursecompare_coursenamecontainer>div:nth-of-type(1) {
	background: linear-gradient(to right, #cbf3e7, #7de1c3);
	/* background-color: #7de1c3; */
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	padding: 10px;
}

.coursecompare_coursenamecontainer h3 {
	color: #224289;
}

.cssfacross {
	background-color: #ffffff !important;
	color: red;
	/* color: #55aad3; */
	/* background-color: #55aad3 !important;
  color: white; */
	padding: 4px;
	border-radius: 50%;
	cursor: pointer;
}

.coursecompare_coursenamecontainer .col-md-12 {
	padding: 10px !important;
}

.coursecompare_coursenamecontainer .col-md-12 p {
	text-transform: uppercase;
	font-size: 16px;
	font-weight: bold;
}

.coursecompare_coursenamecontainer .col-md-6 {
	padding: 10px !important;
}

.coursecompare_coursenamecontainer .col-md-6 p {
	text-transform: uppercase;
	font-size: 16px;
	font-weight: bold;
}

.coursecompare_coursenamecontainer .col-md-4 {
	padding: 10px !important;
}

.coursecompare_coursenamecontainer .col-md-4 p {
	text-transform: uppercase;
	font-size: 16px;
	font-weight: bold;
}

.coursecompare_coursenamecontainer .col-md-3 {
	padding: 10px !important;
}

.coursecompareinsidecontainer {
	border-right: 1px solid #ccc;
}

.coursecompare_coursenamecontainer .col-md-3 p {
	text-transform: uppercase;
	font-size: 16px;
	font-weight: bold;
}

.coursecompare_coursenamecontainer h5 {
	background-color: #7de1c3 !important;
	cursor: pointer;
	padding: 10px 20px !important;
	font-size: 14px !important;
	border-radius: 10px;
}

.coursecompare_uppercontainer {
	margin-top: 10px;
	box-shadow: 0 0 20px #ccc;
	border-radius: 10px;
}

.coursecompare_uppercontainer>div:nth-of-type(1) {
	background: linear-gradient(to right, #cbf3e7, #7de1c3);
	/* background-color: #7de1c3; */
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	padding: 10px;
}

.coursecompare_uppercontainer h3 {
	color: #224289;
}

.coursecompare_upperprovidercontainer {
	margin-top: 15px;
	box-shadow: 0 0 20px #ccc;
	border-radius: 10px;
}

.coursecompare_upperprovidercontainer>div:nth-of-type(1) {
	background: linear-gradient(to right, #cbf3e7, #7de1c3);
	/* background-color: #7de1c3; */
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	padding: 10px;
}

.coursecompare_upperprovidercontainer h3 {
	color: #224289;
}

.coursecompare_uppercontainer .col-md-3 {
	padding: 10px 20px !important;
	border-right: 1px solid #ccc;
	overflow: auto;
}

.coursecompare_uppercontainer .col-md-3:nth-of-type(4) {
	border-right: none;
}

.coursecompare_uppercontainer .col-md-4 {
	padding: 10px 20px !important;
	border-right: 1px solid #ccc;
	overflow: auto;
}

.coursecompare_uppercontainer .col-md-4:nth-of-type(4) {
	border-right: none;
}

.coursecompare_uppercontainer .col-md-6 {
	padding: 10px 20px !important;
	border-right: 1px solid #ccc;
	overflow: auto;
}

.coursecompare_uppercontainer .col-md-6:nth-of-type(4) {
	border-right: none;
}

.coursecompare_uppercontainer .col-md-12 {
	padding: 10px 20px !important;
	border-right: 1px solid #ccc;
	overflow: auto;
}

.coursecompare_uppercontainer .col-md-12:nth-of-type(4) {
	border-right: none;
}

.coursecompare_lowercontainer {
	margin-top: 10px;
	box-shadow: 0 0 20px #ccc;
	border-radius: 10px;
}

.coursecompare_lowercontainer>div:nth-of-type(1) {
	background: linear-gradient(to right, #cbf3e7, #7de1c3);
	/* background-color: #55aad3; */
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	padding: 10px;
}

.coursecompare_lowercontainer h3 {
	color: #224289;
}

.courseproviderinsidecontainer {
	padding: 10px !important;
	border-right: 1px solid #ccc;
	overflow: auto;
}

.courseproviderinsidecontainer:last-child {
	border-right: none;
}

.coursecompare_requirementcontainer {
	padding: 10px !important;
	border-right: 1px solid #ccc;
}

.coursecompare_requirementcontainer:last-child {
	border-right: none;
}

.footer_container {
	background-color: #00140e;
	color: white;
	padding: 15px;
	position: relative;
	z-index: 999;
	width: 100%;
}

.mainaccount {
	display: flex;
	background: #f5f6f8;
}

.mainaccount_left {
	flex: 0.2;
	max-width: 20% !important;
}

.mainaccount_right {
	flex: 0.8;
	max-width: 80% !important;
	padding-top: 110px;
}

.account_footer {
	position: fixed;
	width: 80%;
	bottom: 0;
}

@media screen and (min-width: 1500px) and (max-width: 2000px) {
	.mainaccount_left {
		flex: 0.15;
		max-width: 15% !important;
	}

	.mainaccount_right {
		flex: 0.85;
		max-width: 85% !important;
	}

	.account_footer {
		position: fixed;
		width: 85%;
		bottom: 0;
	}
}

@media screen and (min-width: 2000px) {
	.mainaccount_left {
		flex: 0.1;
		max-width: 10% !important;
	}

	.mainaccount_right {
		flex: 0.9;
		max-width: 90% !important;
	}

	.account_footer {
		position: fixed;
		width: 90%;
		bottom: 0;
	}
}

.account_sidebar {
	background-color: #fff;
	/* position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto; */
	border-right: 1px solid #dce1ed;
}

.account_sidebar_inn {
	padding-top: 120px;
}

.account_sidebarimgcontainer {
	padding: 10px;
}

.account_sidebarimgcontainer img {
	cursor: pointer;
	width: 100%;
	height: 75px;
}

.account_sidebarelementcontainer {
	/* padding: 10px; */
	margin-left: 15px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	align-items: center;
	display: flex;
	cursor: pointer;
	margin-top: 5px;
	transition: all 0.4s ease-in;
	font-size: 14px;
	color: #999da8;
	border-left: 2px solid #fff;
}

.account_sidebarelementcontainer:hover {
	background-color: #fff;
}

.account_sidebarelementcontainer:hover p,
.account_sidebarelementcontainer:hover i {
	color: #fe8518;
}

.activeaccount {
	background-color: #fff3e8;
	cursor: text !important;
	color: #fe8518;
	border-left: 2px solid #fe8518;
}

.account_sidebarelementcontainer i {
	transform: scale(1.2);
	margin-right: 10px;
	margin-left: 10px;
	color: #999da8;
}

.account_sidebarelementcontainer p {
	margin-bottom: 0px !important;
	font-size: 19px;
}

.activeaccount i,
.activeaccount p {
	color: #fe8518 !important;
}

.accountbody {
	padding: 20px 40px 40px 40px;
	transition: all 0.4s ease-in;
	background: #f5f6f8;
}

.accountbody .nav-item .active {
	background-color: #fff !important;
	color: #00ca8e !important;
	font-weight: bold;
	border-bottom: 5px solid #00ca8e !important;
}

.accountbody .nav-link {
	color: #999da8 !important;
	border: 0 !important;
}

.tbb {
	width: 100%;
	max-width: 100%;
	margin-bottom: 20px;
	border: 0px solid #ddd !important;
}

.thb {
	background-color: #fff;
}

.thb th {
	padding: 5px 12px !important;
	color: #07122c !important;
	border: 0 !important;
	border-top: 0px solid #dce1ed !important;
	border-bottom: 1px solid #dce1ed !important;
	/* background: url(./../../assets/images/accountd-table-th-arrow.png) no-repeat
		center right; */
}

.accountbody-title {
	color: #07122c;
	font-size: 24px;
	font-weight: 700;
	padding: 0px;
	margin: 0px 0px 20px;
}

.accountbody .tab-pane td {
	color: #07122c !important;
	padding-left: 20px !important;
	font-size: 14px;
}

.accountenquiry {
	padding: 0px;
	border: 1px solid #dce1ed;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
	border-radius: 12px;
	background: #fff;
	margin-bottom: 50px !important;
	overflow: hidden;
}

.accountenquiry.account-profilebox {
	padding: 30px;
}

.account-profilebox-subtitle {
	position: relative;
}

.account-profilebox-subtitle:after {
	position: absolute;
	left: 0px;
	top: 12px;
	background: #dce1ed;
	height: 1px;
	width: 100%;
	content: '';
}

.account-profilebox h4 {
	color: #07122c;
	font-weight: 700;
	font-size: 16px;
	display: inline-block;
	background: #fff;
	padding: 0px 20px 0px 0px;
	position: relative;
	z-index: 9;
}

.account-profilebox button.btn {
	width: 240px;
	height: 56px;
	background: #00ca8e;
	border-radius: 8px;
	color: #fff;
	font-size: 20px;
	font-weight: 600;
}

.account-profilebox label {
	font-weight: 600;
	font-size: 14px;
}

.account-profilebox .form-control,
.account-profilebox .readonlyformcontrol {
	background: #f5f6f8;
	border-radius: 8px;
	border-color: #f5f6f8;
	height: 56px !important;
}

.accountenquiry td {
	color: #07122c !important;
	padding-left: 20px !important;
	font-size: 14px;
}

.accountenquiry tbody {
	color: #224289 !important;
	background: #fff;
}

.accountheading {
	margin-bottom: 20px;
	color: #224289;
}

body .agent-register-main {
	margin-top: 110px !important;
}

.login {
	margin-top: 110px;
	margin-bottom: 20px;
}

.login h2 {
	color: #224289;
	font-weight: 600;

}

.login_h4 {
	color: #224289;
	font-weight: bold;
}

.loginwidthsize {
	width: 30%;
}

@media screen and (max-width: 1100px) {
	.loginwidthsize {
		width: 50%;
	}
}

@media screen and (max-width: 600px) {
	.loginwidthsize {
		width: 90%;
	}
}

.login_container {
	margin: 0 auto;
	padding: 20px;
	box-shadow: 0 0 20px #ccc;
	border-radius: 10px;
}

.login_container label {
	color: #224289;
	font-weight: bold;
}

.login_container h4 {
	color: #224289;
	font-weight: bold;
	/* text-align: center; */
}

.login_container span {
	color: #224289;
	font-weight: bold;
	cursor: pointer;
}

.login_container .form-control {
	border: none !important;
	background-color: #f5f5f5 !important;
	padding-left: 40px !important;
}

.agentcurrency_input {
	position: relative;
}

.agentcurrency_input input {
	border: none !important;
	background-color: #f5f5f5 !important;
	padding-left: 40px !important;
	width: 100%;
}

.login_container .designi {
	transform: scale(1.5);
	position: absolute;
	top: 12px;
	left: 12px;
	opacity: 1;
	color: #989898;
}

.login_container .text-right {
	opacity: 0.7;
	cursor: pointer;
}

.login_container button {
	width: 100%;
	font-weight: bold;
	background-color: #7de1c3;
	color: #ffffff;
}


.fonticonpositioning {
	position: absolute;
	right: 10px;
	top: 40px;
	transform: translateY(-50%);
	font-size: 1.4em;
	color: #989898;
	cursor: pointer;
}

.register_icon {
	position: absolute;
	top: 7px;
	right: 11px;
	transform: scale(2);
	color: #989898;
}

.passwordiconpositioning {
	position: absolute;
	right: 10px;
	top: 15px;
	font-size: 1.4em;
	color: #989898;
	cursor: pointer;
}

.loginpage_footer {
	position: fixed;
	width: 100%;
	bottom: 0;
}

.resetcontainer {
	margin-top: 60px;
}

/* Header css  */
.account-header {
	border-bottom: 1px solid #dce1ed;
}

.main-header {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	z-index: 99999;
	background-color: #fff;
}

.main-header .nav-link {
	color: #525b70;
	font-family: 'Sora', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	padding: 10px 10px !important;
	display: block;
	cursor: pointer;
}

.main-header .nav-link.active {
	color: #fe8518 !important;
	font-weight: 700;
}

.nav-item-enquiry {
	background: #fff3e8;
	border-radius: 30px;
	color: #fe8518 !important;
}

.nav-item-signup {
	background: #fe8518 !important;
	border-radius: 30px;
	color: #fff !important;
}

.main-header .nav-item.active a {
	color: #fe8518 !important;
	font-weight: 700;
}

.headertextcss {
	color: #224289 !important;
	font-size: 15px !important;
	font-weight: 500;
	margin-left: 15px !important;
	margin-right: 15px !important;
	margin-top: 2px;
	text-transform: capitalize;
}

.headerenquirytext {
	cursor: pointer;
	background: #7de1b7 !important;
	border-radius: 30px !important;
	padding-left: 30px !important;
	padding-right: 30px !important;
	font-size: 15px !important;
	font-weight: 500;
	margin-left: 15px !important;
	margin-right: 15px !important;
	margin-top: 2px;
}

.headertextcssnew {
	color: white !important;
	background: #55aad3 !important;
	border-radius: 30px !important;
	padding-left: 30px !important;
	padding-right: 30px !important;
	font-size: 15px !important;
	font-weight: 500;
	margin-right: 15px !important;
	margin-top: 2px;
	text-transform: capitalize;
	cursor: pointer;
}

.headertextcss a,
.headersignupcss a,
.headertextcssnew a {
	color: #ffffff !important;
	font-size: 15px !important;
	font-weight: 500;
	text-transform: capitalize;
}

.headertextcss i,
.headertextcssnew i {
	padding-right: 5px;
	transform: scale(1.1);
}

.headertextcss .dropdown-menu,
.headertextcssnew .dropdown-menu {
	left: -15px !important;
	margin-top: 10px !important;
	background-color: #55aad3 !important;
}

.headertextcss .dropdown-item:focus,
.headertextcss .dropdown-item:hover,
.headertextcssnew .dropdown-item:focus,
.headertextcssnew .dropdown-item:hover {
	color: white !important;
	background-color: #224289 !important;
}

.headersignupcss {
	color: white !important;
	background: #7de1b7 !important;
	border-radius: 30px !important;
	padding-left: 30px !important;
	padding-right: 30px !important;
	text-align: center;
	max-width: 150px;
	height: 38px;
	margin-top: 4px;
	font-size: 14px;
}

.headersignupcss .dropdown-menu {
	left: -15px !important;
	margin-top: 10px !important;
	background-color: #224289 !important;
}

.headersignupcss .dropdown-item:focus,
.headersignupcss .dropdown-item:hover {
	color: white !important;
	background-color: #1e2b47 !important;
}

.comparemodal_headingtext {
	color: #224289;
}

.comparemodal_cross {
	color: white;
	background-color: #55aad3;
	border-radius: 50%;
	padding: 4px 7px;
	cursor: pointer;
}

.comparemodal_comparebutton {
	background-color: #7de1c3 !important;
	margin-top: 10px;
}

.plusspan {
	transform: scale(1.6);
}

.comparedetail_modalbody {
	box-shadow: 0 0 20px #ccc;
	border-radius: 10px;
	margin-bottom: 10px;
}

.comparedetail_modalbody .col-md-1 {
	padding: 10px;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.comparedetail_modalbody .col-md-1 i {
	cursor: pointer;
	transform: scale(1.5);
	color: #ff0000;
}

.comparedetail_common {
	border-right: 1px solid #ccc;
	padding: 10px;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.header_navbarimagecontainer img {
	cursor: pointer;
	width: 170px;
	height: 43px;
}

.header_input {
	border-radius: 0px !important;
	padding: 10px !important;
	font-size: 14px !important;
	background-color: #eaecf3 !important;
	border: none !important;
	box-shadow: inset 0 0 3px #ccc;
	min-width: 210px;
}

.header_i {
	color: white;
	background-color: #224289;
	height: 55px;
	padding-top: 18px;
	margin-top: -8px;
	padding-left: 25px;
	padding-right: 25px;
	cursor: pointer;
	transform: scale(0.7);
	margin-left: -10px;
}

.contactuscontainer {
	margin-top: 50px;
}

.contactuscontainer label {
	color: #224289;
	font-size: 20px;
	font-weight: bold;
}

.contactuscontainer .col-md-8 .row {
	margin: 30px 0 !important;
}

.contactuscontainer .col-md-12 .row {
	margin: 30px 0 !important;
}

/* .contactuscontainer .col-md-4 .row {
  margin: 30px 0 !important;
} */
.contactuscontainer .col-md-4 p {
	margin-bottom: 0 !important;
}

.contactuscontainer i {
	color: #55aad3;
	transform: scale(3);
	margin-top: 16px;
}

.nowsearchingby img {
	padding-right: 4px;
	padding-left: 15px;
	height: 10px;
	margin-top: 8px;
	cursor: pointer;
}

.nowsearchingby span {
	color: #e55c21;
	font-size: 14px;
	margin-top: 2px;
}

.courseclearalltext {
	text-align: end;
	padding-right: 12px;
	font-size: 14px;
	color: #224289;
	cursor: pointer;
	width: fit-content;
	padding-top: 10px;
	font-weight: 500;
}

.accountbody_responsivenav .nav-item img {
	margin-top: 10px;
	/* margin-left: -1px; */
	width: 2px;
	height: 23px;
}

.filedownloadtd {
	cursor: pointer;
	color: #224289;
}

/* .coursecompare_icondisplaymodal {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: none !important;
  opacity: 0.5;
  border: 1px solid #ccc;
  border-radius: 100px;
  padding: 4px;
  transform: scale(1.5);
  transition: all 0.5s ease !important;
  cursor: pointer;
}
.coursecompare_requirementcontainer:hover .coursecompare_icondisplaymodal {
  display: block !important;
} */
.coursecompare_lowershowmodal:hover {
	cursor: pointer;
	color: #224289;
	font-weight: bold;
	transition: all 0.3s ease;
}

.changecolormodal .modal-content {
	background-color: #7de1c3;
}

.coursecompare_bodymaincontainer {
	margin: 100px 50px !important;
}

.coursecompare_bodymaincontainernew {
	margin: 50px 50px !important;
	/* padding: 1px;
  border-radius: 10px;
  box-shadow: 0 0 50px #ccc; */
}

.courseComparelower_applybutton {
	background-color: #00ca8e !important;
	cursor: pointer;
	/* font-size: 14px !important; */
	border-radius: 10px;
	text-align: center;
	padding: 10px 20px !important;
	height: 50px !important;
}

.compare_clearall h5 {
	cursor: pointer;
	color: #55aad3;
	transition: all 0.4s ease;
	margin-bottom: 0 !important;
}

.compare_clearall h5:hover {
	color: #224289;
}

.courseproviderinsidecontainernew {
	-webkit-border-radius: 8px 8px 0 0;
	border-radius: 8px 8px 0 0;
	padding: 10px !important;
	border: 1px solid #dce1ed;
	border-bottom: 0px solid #fff;
	overflow: auto;
	background-color: #fff;
	/* background-color: #7de1b7; */
	color: #07122c;
	margin: 0px 5px;
}

.courseproviderinsidecontainernew h4 {
	font-weight: 700;
	font-size: 16px;
	color: #07122c;
}

.courseproviderinsidecontainernew h6 {
	font-weight: 400;
	font-size: 12px;
	color: #07122c;
}

.coursecomparenew_tablecontainer {
	flex: 0.12;
	padding: 10px;
	display: flex;
	align-items: center;
	min-height: 70px;
	max-width: 12%;
}

.coursecomparenew_tableparentcontainer {
	display: flex;
	margin: 0 0px 30px 0px;
	/* border-bottom: 1px solid #ccc; */
	justify-content: center;
}

.header_belowcontainer {
	background-color: #55aad3;
}

.header_belowcontainer p {
	color: #ffffff;
	margin-bottom: 0 !important;
	padding: 15px 25px !important;
	cursor: pointer;
	transition: all 0.3s ease;
}

.header_belowcontainer p.active {
	background-color: #224289;
	cursor: text;
}

/* .course_left input[type="checkbox"] {
  cursor: pointer;
  font-size: 17px;
  visibility: hidden;
} */
.course_left input {
	accent-color: #00ca8e;
	color: #fff;
}

/* .course_left input[type="checkbox"]:after {
  content: " ";
  background-color: #fff;
  display: inline-block;
  padding-bottom: 5px;
  color: green;
  width: 20px;
  height: 20px;
  visibility: visible;
  border: 1px solid green;
  padding-left: 0px;
  border-radius: 5px;
  margin-top: 6px;
}

.course_left input[type="checkbox"]:checked:after {
  content: "\2714";
  padding: -5px;
  font-weight: bold;
} */

.mainaccount_right .accountbody_tabnav .nav-item img {
	display: none !important;
}

.clearfix {
	clear: both;
}

.filter-mobile-visible {
	display: none !important;
}

@media screen and (max-width: 800px) {

	/* .footer_container{position: relative !important; font-size:12px !important;} */
	.navbar {
		justify-content: flex-end !important;
		padding: 0 !important;
	}

	.home-clients-logo {
		/* position: relative;
    bottom: 0;
    margin-bottom: -25px; */
	}

	.navbar-collapse {
		background: #fff;
		position: fixed !important;
		left: 0px;
		width: 100% !important;
		box-sizing: border-box;
		padding: 20px;
		top: 48px;
		height: calc(100vh - 48px) !important;
		overflow: auto;
	}

	.navbar-collapse li {
		margin-bottom: 10px;
	}

	.homepage_selectioncontainer {
		margin-top: 90px;
	}

	.homepage_selectioncontainer h1 {
		font-size: 37px;
	}

	.home-clients-logo img {
		width: 20%;
	}

	.home-form {
		border-radius: 16px;
		margin-bottom: 80px;
	}

	.homepage_formcontainer .row {
		flex-direction: column;
		padding: 0px 15px;
	}

	.homepage_formcontainer .row .col {
		border-right: 0px !important;
		border-bottom: 1px solid #dce1ed;
		margin: 0px;
		padding: 0px;
	}

	body .homepage_formcontainer .form-control {
		padding-left: 0px !important;
	}

	.homepage_formcontainer .col:last-child {
		border-bottom: 0px solid #dce1ed !important;
		margin-top: 20px;
		margin-bottom: 10px;
	}

	.contact-content {
		padding-left: 15px;
		padding-right: 15px;
	}

	.contactus-form .row {
		flex-direction: column;
	}

	.innerpage-selectioncontainer {
		padding-top: 80px !important;
	}

	.title-n-clear {
		padding: 0px 15px;
	}

	.title-n-clear h2 {
		float: left;
		width: 73%;
		text-align: left !important;
	}

	.title-n-clear .compare-clearsearch {
		margin: 0px !important;
	}

	.title-n-clear .btn {
		padding: 0px !important;
	}

	.coursecompare_bodymaincontainernew {
		margin: 15px !important;
	}

	.compare-headlines {
		text-align: left;
	}

	.comparenew-row {
		flex-direction: column !important;
	}

	.comparenew-row-colm {
		width: 300px !important;
		max-width: 300px !important;
		min-width: 300px !important;
		float: left !important;
	}

	.coursecomparenew_tableparentcontainer {
		flex-direction: column;
	}

	.compare-headlines h5 {
		margin: 0px 0px 20px 10px;
	}

	.login h2 {
		font-size: 22px;
	}

	.loginbox-mb {
		flex-direction: column;
	}

	.login-bg.login.coursecompare_bodymaincontainer {
		height: inherit;
		padding-top: 100px;
		padding-bottom: 0px;
		background-size: 600px;
		background-position: top center;
	}

	.login .login-leftbox h2 {
		line-height: inherit;
		font-size: 25px;
	}

	.loginbox {
		overflow: visible;
	}

	.login-rightform-inn {
		padding: 0px;
		margin: 50px 0px;
	}

	.login-rightform {
		height: inherit;
		overflow: inherit;
	}

	.text-already-register.text-right {
		text-align: center !important;
	}

	.btn-next-register {
		width: 100% !important;
	}

	.already-register-first-nm {
		margin-bottom: 20px !important;
	}

	.login-top-label-circle {
		width: 60px;
	}

	.comparebox-item {
		min-height: 70px;
	}

	.filter-mobile-visible {
		display: block !important;
	}

	.mobile-course-filter {
		border: 1px solid #dce1ed;
	}

	.mobile-course-filter {
		padding: 15px 0px;
		font-weight: 700;
	}

	.mobile-course-filter-sort {
		border-right: 1px solid #dce1ed;
	}

	.mobile-course-filter-sort a {
		display: block;
	}

	.mobile-course-filter-sort i {
		float: right;
	}

	.filter-mobile-visible a {
		color: #000;
	}

	.mobile-course-filter-box {
		display: none;
	}

	.mainaccount_left {
		position: fixed;
		left: 0px;
		bottom: 0px;
		max-width: 100% !important;
		display: block !important;
		width: 100% !important;
		z-index: 99999;
	}

	.account_sidebar_inn {
		padding-top: 0px !important;
		display: flex;
		flex-direction: row;
	}

	.account_sidebar {
		height: inherit;
	}

	.mainaccount_right {
		max-width: 100% !important;
	}

	.account_sidebarelementcontainer p {
		display: none !important;
	}

	.account_sidebarelementcontainer {
		margin: 0px !important;
	}

	.mainaccount_right .navbar {
		display: none !important;
	}

	.account_sidebarelementcontainer.activeaccount {
		border-left: 0px !important;
		padding: 20px 15px !important;
		border-top: 2px solid #fe8518 !important;
	}

	.account_sidebarelementcontainer {
		border-left: 0px !important;
		border-top: 2px solid #fff !important;
		border-radius: 0px !important;
		padding: 20px 15px !important;
		width: 17%;
		justify-content: center;
	}

	.account_sidebarelementcontainer i {
		margin: 0px !important;
	}

	.accountbody {
		padding: 0px 15px 15px 15px !important;
	}

	.mainaccount {
		display: block;
	}

	.coursesearchbody_overviewbutton {
		margin-right: 7px;
	}

	.accountbody_tabnav li {
		margin-bottom: 10px !important;
	}
}

.show-desktop-only {
	display: block;
}

.show-mobile-only {
	display: none;
}

.visa-label-mobile {}

.accunt-rpt-tb-content {
	padding: 0px;
	border-bottom: 1px solid #dce1ed;
	font-size: 14px;
	color: #07122c;
}

.accunt-rpt-tb-content h4 {
	padding: 10px 0px;
	margin: 0px;
	font-size: 14px;
	font-weight: 400;
	color: #07122c;
}

.accunt-rpt-tb-content h6 {
	padding: 0px 10px 5px 0px;
	margin: 0px;
	font-size: 12px;
	font-weight: 400;
	color: #999da8;
}

.accunt-rpt-tb-text {
	padding: 0px 10px 0px 0px;
}

.accunt-rpt-tb-item {
	float: left;
	width: 50%;
	margin-bottom: 10px;
}

.tooltip-icon {
	position: relative;
}

.tooltip-icon i {
	cursor: pointer;
}

.tooltip-icon .tooltip-content {
	display: none;
	position: absolute;
	left: 12px;
	width: 100px;
	background: #fff3e8;
	color: #fe8518 !important;
	padding: 5px;
	border-radius: 10px;
	text-align: center;
	top: -7px;
	z-index: 999;
}

.tooltip-icon:hover .tooltip-content {
	display: block;
}

.btn.overview-cta-desktop {}

.overview-cta-mobile {
	display: none;
}

@media only screen and (max-width: 600px) {
	.overview-cta-mobile {
		display: inline-block;
		width: 50%;
	}

	.btn.overview-cta-desktop {
		display: none;
	}

	.course-searchrightbarlast.centerofcontainer {
		display: block !important;
		padding-right: 10px !important;
		margin-top: 0px !important;
	}

	.course-searchrightbarlast button {
		width: 100% !important;
	}

	.course-searchrightbarlast .p-2 {
		float: left;
		width: 48%;
	}

	.course-searchrightbarlast .coursesearchbody_overviewbutton {
		margin-top: 0px;
	}

	.show-desktop-only {
		display: none;
	}

	.show-mobile-only {
		display: block;
	}

	.visa-label-mobile {
		display: block;
		font-size: 12px;
		font-weight: 400;
		color: #999da8;
	}

	.visadoc-item {
		padding-left: 15px;
	}

	.visadoc-item p {
		text-align: left !important;
	}

	.contactus-form .btn-green,
	.account-profilebox .btn {
		width: 100% !important;
	}

	.accountbody_tabnav_outer {
		position: relative;
		height: 70px;
		overflow-x: auto;
		margin-bottom: 30px;
	}

	.accountbody_tabnav_outer .accountbody_tabnav {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 580px;
	}

	.coursecompare_bodymaincontainernew {
		overflow-x: scroll;
		width: 100%;
	}

	.coursecomparenew_tableparentcontainer {
		overflow-x: scroll;
		width: 1210px;
		display: block;
	}
}