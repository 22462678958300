.progress-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.step-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.circle {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.completed {
    background-color: green;
    color: white;
}

.incomplete {
    background-color: #e0e0e0;
    color: black;
}

.check-mark {
    font-size: 24px;
}

.step-number {
    font-size: 18px;
}

.step-text {
    font-size: 14px;
}

.line {
    width: 50px;
    height: 2px;
    background-color: #e0e0e0;
    margin: 0 10px;
}